<template>
  <v-app>
    <v-banner single-line class="banner">
      <v-img
        src="https://h5.ophyer.cn/official_website/other/H5EfficiencyCloundBanner.png"
        height="100%"
        :transition="false"
        class="d-flex align-center"
        
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              size="80"
              color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <v-container color="transparent" class="d-flex  max-width-1200">
          <v-card color="transparent" flat >
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title class="white--text font-size-h5banner-title" >
                  万效云
                </v-card-title>
                <v-card-text class="white--text py-0  font-size-h5banner-tip">
                 AR/VR沉浸式互动体验<br/>构建广告新生态
                </v-card-text>
              </div>
            </div>
          </v-card>
        </v-container>
      </v-img>
    </v-banner>
    <!-- AR/VR沉浸式互动广告 -->
    <v-card color="#F5F8FC" flat class="wrap2 py-6 position-r">
      <v-card-title class="justify-center font-weight-bold font-size-20  pa-0 line-height-28">沉浸式互动广告</v-card-title>
      <v-tabs v-model="tab" background-color="transparent" 
              class="xs-tab">
        <v-tab v-for="(v, k) in data.second" :key="k" class="pa-0"
               :class="[k === 0 ? 'ml-5':'ml-6',k === data.second.length - 1 ? 'mr-5':'']"
               style="min-width: unset">
          <b class="color-666 font-size-14 letter-spacing">{{ v.tabTitle }}</b>
        </v-tab>
      </v-tabs>
      <v-tabs-items class="mt-3 mx-5" v-model="tab" style="border-radius: 10px;">
        <v-tab-item v-for="(v, k) in data.second" :key="k" :transition="false">
          <v-card flat color="white" class="pa-5">
            <div class="right-box" style="background: #2181EA;">
              <v-row>
                <v-col cols="6" v-for="(m,n) in v.btns" :key="n" class="img-item">
                  <v-img :src="m.imgUrl" width="100%" min-height="200" contain>
                    <template v-slot:placeholder><img-placeholder></img-placeholder></template>
                      <img :class="m.id == 1? 'item-img-big' : ''"   :src="m.src1" alt="" v-show="m.id==1">
                      <img :class="m.id == 2? 'item-img-big2' : ''"  :src="m.src1" alt="" v-show="m.id==2">
                      <img :class="m.id == 3? 'item-img-big3' : ''"  :src="m.src1" alt="" v-show="m.id==3">
                      <img :class="m.id == 4? 'item-img-big4' : ''"  :src="m.src1" alt="" v-show="m.id==4">
                  </v-img>
                </v-col>
              </v-row>
            </div>
            <v-card-title class="pa-0 mt-4 font-size-18 font-weight-bold line-height-25">{{v.lunTitle}}</v-card-title>
            <v-card-text class="pa-0 my-1 line-height-22"  v-text="v.tip" />
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <div class="tab-btn-prev" @click.stop="handleTabBtn('prev')"></div>
      <div class="tab-btn-next" @click.stop="handleTabBtn('next')"></div>
    </v-card>
    <!-- 万效云产品优势 -->
    <v-card class="px-4 py-6" flat>
      <v-card-title class="justify-center font-weight-bold font-size-20 pa-0 line-height-28">我们的优势</v-card-title>
      <div class="mt-4">
        <v-row v-for="(row,rowIndex) in data.advantageList" :key="rowIndex">
          <v-card class="pa-3 d-flex h5-card" min-height="90" width="100%" flat>
            <v-img :src="row.img" max-width="65" contain position="top"></v-img>
            <div class="ml-4">
              <v-card-title class="pa-0 mt-1 font-size-16 font-weight-bold line-height-20">
                {{row.title}}
              </v-card-title>
              <v-card-text class="pa-0 mt-2 font-size-13 color-666 line-height-20">
                {{row.text}}
              </v-card-text>
            </div>
          </v-card>
        </v-row>
      </div>
    </v-card>
    <v-card class="py-5 px-4" flat color="#F5F8FC">
      <v-card-title class="justify-center font-weight-bold font-size-20 pa-0 line-height-28">整合主流渠道，媒体推广全方位</v-card-title>
      <v-img class="mt-6" width="100%" contain src="https://h5.ophyer.cn/official_website/other/EfficiencyCloudCaseH5.png">
        <template v-slot:placeholder><img-placeholder></img-placeholder></template>
      </v-img>
    </v-card>
  </v-app>
</template>

<script>
import contentData from '@/static/contentData.js';
export default {
  name: 'h5EfficiencyCloud',
  components: {
  },
  data() {
    return {
      data: contentData.EfficiencyCloudh5,
      tab: 0,
    }
  },
  watch: {
  },
  created() {
  },
  methods: {
    handleTabBtn: function (type){
      const maxTab = this.data.second.length - 1;
      if(type === "prev"){
        if(this.tab === 0){
          this.tab = maxTab;
        }else {
          this.tab--;
        }
      }else if(type === "next"){
        if(this.tab === maxTab){
          this.tab = 0;
        }else {
          this.tab++;
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.item-img-big {
  width: 78%;
  height: 18%;
  position: absolute;
  top: 14%;
  right: 10%;
  animation-delay: 1s;
  animation-fill-mode:forwards;
	// 关键帧名称
	-webkit-animation-name:h5scaleDraw1;
	// 动画的速度曲线
  -webkit-animation-timing-function:ease-in-out;
	// 动画播放的次数
  -webkit-animation-iteration-count:infinite;
	// 动画所花费的时间
  -webkit-animation-duration:4s;
}

.item-img-big2{
  width: 66%;
  height: 15%;
  position: absolute;
  bottom: 24%;
  right:17%;
  animation-delay: 10s;
  animation-fill-mode:forwards;
	// 关键帧名称
	-webkit-animation-name:scaleDraw;
	// 动画的速度曲线
  -webkit-animation-timing-function:ease-in-out;
	// 动画播放的次数
  -webkit-animation-iteration-count:infinite;
	// 动画所花费的时间
  -webkit-animation-duration:4s;
}

.item-img-big3{
  width: 89%;
  height: 69%;
  position: absolute;
  top: 13%;
  right:5%;
  border-radius: 10px;
  animation-delay: 3s;
  animation-fill-mode:forwards;
	// 关键帧名称
	-webkit-animation-name:h5scaleDraw1;
	// 动画的速度曲线
  -webkit-animation-timing-function:ease-in-out;
	// 动画播放的次数
  -webkit-animation-iteration-count:infinite;
	// 动画所花费的时间
  -webkit-animation-duration:5s;
}

.item-img-big4{
  width: 275%;
  height: 85%;
  position: absolute;
  top: 12%;
  right:-85%;
  animation-delay: 1s;
  z-index: -20;
  animation-delay: 10s;
  animation-fill-mode:forwards;
  // 关键帧名称
  -webkit-animation-name:move;
  // 动画的速度曲线
  -webkit-animation-timing-function:ease-in-out;
  // 动画播放的次数
  -webkit-animation-iteration-count:infinite;
  // // 动画所花费的时间
  -webkit-animation-duration:10s;
}

.wrap2{
  .right-box{
    position: relative;
  }
}
::v-deep .v-tab--active{
  b{
    color: #2181EA;
  }
}
::v-deep .v-slide-group__wrapper{
  justify-content: center;
}
::v-deep .xs-tab .v-slide-group__content{
  justify-content: center;
}
.banner{
  position: relative;
  ::v-deep .v-banner__wrapper,::v-deep .v-banner__content,::v-deep .v-banner__text{
    padding: 0 !important;
    height: 100%;
  }
  .banner-box {
    margin-top: -100px;
    .banner-text {
      p:nth-child(1) {
        font-size: 82px;
        //font-family: SourceHanSansCN-Regular;
        font-weight: 400;
        color: #fff;
        line-height: 88px;
      }
      p:nth-child(2) {
        margin-top: 25px;
        font-size: 24px;
        font-weight: 100 !important;
        color: #fff;
      }
      .banner-btn {
        margin-top: 90px;
        width: 200px;
        height: 60px;
        background: #0568FD;
        border-radius: 35px;
        font-size: 20px !important;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 60px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
  @media (min-width: 960px) {
    .container {
      max-width: 1200px !important;
    }
  }
  
}
</style>
